import React, { useState, useEffect, useMemo } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  DropdownButton,
  Dropdown,
  InputGroup,
} from "react-bootstrap";
import "../addedcss/prodDemo.css";
import axios from "axios";
import ProdApiDemo from "../components/APIDemos/ProdApiDemo.js";
import countryList from "react-select-country-list";
const ProdDemo = () => {
  const [cat, setCat] = useState(true);
  const [logo, setLogo] = useState(true);
  const [merchantWebsite, setMerchantWebsite] = useState(true);
  const [footprint, setFootprint] = useState(false);
  const [cleanDescription, setCleanDescription] = useState(false);

  const [catValue, setCatValue] = useState("");
  const [logoValue, setLogoValue] = useState("");
  const [merchantWebsiteValue, setMerchantWebsiteValue] = useState("");
  const [footprintValue, setFootprintValue] = useState("");
  const [cleanDescriptionValue, setCleanDescriptionValue] = useState("");

  const [query, setQuery] = useState("");
  const [description, setDescription] = useState("");
  // const [currency, setCurrency] = useState("USD");
  const [country, setCountry] = useState("AE");

  const [amount, setAmount] = useState("0.00");
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const currencyOptions = ["USD", "EGP", "AED", "EUR", "SAR", "MXN"];
  const countryOptions = useMemo(() => countryList().getData(), []);

  const getCurrency = (country) => {
    switch (country) {
      case "AE":
        return "AED";
      case "EG":
        return "EGP";
      case "GB":
        return "GBP";
      case "SA":
        return "SAR";
      case "US":
        return "USD";
      case "MX":
        return "MXN";
      case "FR":
        return "EUR";
      case "IT":
        return "EUR";
      case "ES":
        return "EUR";
      case "DE":
        return "EUR";
      case "NL":
        return "EUR";
      case "BE":
        return "EUR";
      case "AT":
        return "EUR";
      case "PT":
        return "EUR";
      case "IE":
        return "EUR";
      case "LU":
        return "EUR";
      case "CY":
        return "EUR";
      case "MT":
        return "EUR";
      case "SK":
        return "EUR";
      case "SI":
        return "EUR";
      case "FI":
        return "EUR";
      case "EE":
        return "EUR";
      case "LV":
        return "EUR";
      case "LT":
        return "EUR";
      case "BG":
        return "EUR";
      case "RO":
        return "EUR";
      case "CZ":
        return "EUR";
      case "HU":
        return "EUR";
      case "PL":
        return "EUR";
      case "DK":
        return "EUR";
      case "SE":
        return "EUR";

      default:
        return "USD";
    }
  };

  // const countryOptions = [
  //   { label: "United Arab Emirates", value: "UAE", currency: "AED" },
  //   { label: "Egypt", value: "EG", currency: "EGP" },
  //   { label: "Europe", value: "EU", currency: "EUR" },
  //   { label: "United Kingdom", value: "GB", currency: "GBP" },
  //   { label: "Saudi Arabia", value: "SA", currency: "SAR" },
  //   { label: "United States", value: "US", currency: "USD" },
  //   { label: "Other", value: "Other", currency: "USD" },
  // ];

  const [buttonState, setButtonState] = useState("disabled");

  let onVerification = () => setButtonState("enabled");

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        url: "https://genify.ai/",
        path: "/txn-data-gui",
        page_type: "tryapiPage",
        event: "page_view",
        title: "Transaction Categorization API",
      });
    }
  }, []);

  const sortCountries = (countries) => {
    let values = ["MX", "SA", "FR", "AE", "EG", "US"];
    for (let i = 0; i < values.length; i++) {
      countries.sort(function(x, y) {
        return x.value == values[i] ? -1 : y.value == values[i] ? 1 : 0;
      });
    }
    return countries;
  };
  var sortedCountries = sortCountries(countryOptions);

  const handleChangeCat = () => {
    setCat(!cat);
    // console.log("clicked", cat);
  };

  const handleChangeLogo = () => {
    setLogo(!logo);
  };

  const handleChangeWebsite = () => {
    setMerchantWebsite(!merchantWebsite);
  };

  const handleChangeFootprint = () => {
    setFootprint(!footprint);
  };

  const handleQueryChange = (e) => {
    // console.log(e.target.value);
    setQuery(e.target.value);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;

    let regex = /^-?\d*(\.\d{0,2})?$/;
    if (!regex.test(value)) return;
    // add zero if decimal point is first char

    if (value.startsWith("-.")) {
      setAmount("-0" + value.substring(1));
    } else if (value.startsWith(".")) {
      setAmount("0" + value);
    } else setAmount(e.target.value);
  };

  const handleAmountFocus = (e) => {
    const value = e.target.value;
    if (value == "0.00") return setAmount("");
    // remove the decimal
    if (value.endsWith(".00")) {
      setAmount(value.substring(0, value.length - 3));
    }
  };

  const handleAmountBlur = (e) => {
    const value = e.target.value;
    if (!value) return setAmount("0.00");
    const roundedValue = parseFloat(value).toFixed(2);
    setAmount(roundedValue);
  };

  const processAmount = (amount) => {
    let firstDigit = amount.search(/\d/);
  };

  const handleChangeCleanDescription = (e) => {
    setCleanDescription(!cleanDescription);
  };

  const parseQuery = (query) => {
    query = query.trim();
    const myArr = query.split(" ");
    const amount = myArr[myArr.length - 1];
    const description = query.substring(0, query.length - (amount.length + 1));
    setDescription(description);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (isNaN(amount)) {
      setResponseMessage("Failure: no amount provided.");
      setLoading(false);
      setCatValue("");
      setLogoValue("");
      setFootprintValue("");
      setCleanDescriptionValue("");
      return;
    }

    window.dataLayer.push({
      url: "https://genify.ai/",
      page_type: "tryapiPage",
      product_type: "Transaction Categorization API",
      query: `${description} ${amount}`,
      event: "run_api",
    });

    let config = {
      headers: {
        authorization: "guipass",
        username: "guiuser",
        category: +cat,
        logo: +logo,
        carbonfootprint: +footprint,
        website: +merchantWebsite,
        account_id: "0",
        model_name: "model_5",
        enable_caching: "0",
        memcached: "0",
      },
      params: {
        // description: description,
        description: query,
        amount: `${amount}`,
        date: "2018-02-02",
        longitude: "31.233334",
        latitude: "30.033333",
        country: country.toLowerCase(),
        mcc: "9000",
        // merchantWebsite: merchantWebsiteValue,
        currency: getCurrency(country),
      },
    };
    const baseURL = "https://pfm.genify.ai/api/v1.0/txn-data/";
    // const baseURL = "http://localhost:5000/api/v1.0/txn-data/";
    axios
      .get(baseURL, config)
      .then((response) => {
        // console.log(response.data)
        setCatValue(response.data["Category Name"]);
        setFootprintValue(response.data["Carbon Footprint"]);
        setLogoValue(response.data["Logo"]);
        setMerchantWebsiteValue(response.data["Merchant Website"]);
        cleanDescription
          ? setCleanDescriptionValue(response.data["Clean Description"])
          : setCleanDescriptionValue("");
        setResponseMessage("Success");
      })
      .catch((error) => {
        setLoading(false);
        setResponseMessage("Failure:");
        setCatValue("");
        setLogoValue("");
        setFootprintValue("");
        setCleanDescriptionValue("");
        setMerchantWebsiteValue("");
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          setResponseMessage(`Failure: ${error.response.data}`);

          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
        // console.log(error.config);
      })
      .then(() => {
        setLoading(false);
      });
  };

  // const handleSelectCurrency = (e) => {
  //   setCurrency(e);
  // };
  const handleSelectCountry = (e) => {
    setCountry(e);
  };
  useEffect(() => {
    parseQuery(query);
  }, [
    cat,
    logo,
    footprint,
    query,
    catValue,
    logoValue,
    footprintValue,
    cleanDescriptionValue,
    loading,
    description,
    country,
  ]);
  return (
    <>
      <Helmet title="Transaction Categorization API | Genify" defer={false}>
        <link rel="canonical" href="https://www.genify.ai/txn-data-gui/" />
      </Helmet>
      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          source: "genify",
          button: "null", // cta, account, null
          path: "productPage",
        }}
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <div className="inner-banner pt-29 pb-md-11 bg-default-1">
          <Row className="justify-content-center pt-5">
            <Container>
              <Col>
                <div className="text-center">
                  <h2 className="title gr-text-2 mb-12 text-left">
                    Transaction Categorization API Playground
                  </h2>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Row className="aligned gap-4">
                    <Col lg={6} md={8}>
                      <Row className="mb-4">
                        <Form.Group className="mb-3 col-md-12">
                          <Form.Label>Query</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Input Transaction Description"
                            onChange={handleQueryChange}
                            value={query}
                          />
                          {/* <Form.Text className="text-muted">
                            Please ensure numeric input is positive and
                            corresponds to an expense.
                          </Form.Text> */}
                        </Form.Group>
                      </Row>
                      <Row className="mb-4 justify-content-between">
                        <Form.Group className="mb-8 flex flex-col col-md-8">
                          {/* <Col> */}
                          <Form.Label>Amount</Form.Label>
                          <InputGroup className="relative">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {// countryOptions.find((c) => c.value == country)
                                //   .currency
                                getCurrency(country)}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              type="text"
                              inputMode="numeric"
                              placeholder="0"
                              onChange={handleAmountChange}
                              value={amount}
                              onBlur={handleAmountBlur}
                              onFocus={handleAmountFocus}
                            />
                            <div
                              style={{
                                background: "lightgray",
                                height: 25,
                                top: "100%",
                                right: "0px",
                              }}
                              className="border-2 border-gray-500 rounded-b-lg flex items-center justify-center overflow-hidden absolute"
                            >
                              <p
                                className="m-0 py-1 px-2 text-gray-500 text-sm cursor-pointer"
                                style={
                                  amount > 0
                                    ? {
                                        color: "white",
                                        background: "#4a63a5",
                                        pointerEvents: "none",
                                      }
                                    : {}
                                }
                                onClick={() => {
                                  setAmount(Math.abs(amount).toFixed(2));
                                }}
                              >
                                Income
                              </p>
                              <div
                                style={{ width: 1 }}
                                className="h-full bg-white"
                              />
                              <p
                                className="m-0 py-1 px-2 text-gray-500 text-sm cursor-pointer"
                                style={
                                  amount < 0
                                    ? {
                                        color: "white",
                                        background: "#4a63a5",
                                        pointerEvents: "none",
                                      }
                                    : {}
                                }
                                onClick={() => {
                                  setAmount((-Math.abs(amount)).toFixed(2));
                                }}
                              >
                                Expense
                              </p>
                            </div>
                          </InputGroup>
                        </Form.Group>

                        <Col md={4}>
                          <Form.Label>Country</Form.Label>
                          <DropdownButton
                            onSelect={handleSelectCountry}
                            title={`${country}`}
                          >
                            {sortedCountries.map((country, i) => (
                              <>
                                {i == 6 && <Dropdown.Divider />}
                                <Dropdown.Item eventKey={country.value}>
                                  {country.label}
                                </Dropdown.Item>
                              </>
                            ))}
                          </DropdownButton>
                        </Col>
                      </Row>
                      <Row
                        className="justify-content-center mt-6"
                        sm={2}
                        xs={2}
                        md={2}
                      >
                        <div className="flex mt-6 justify-content-start align-items-center">
                          <label
                            className="switch "
                            style={{ display: "block", margin: "0 1vw" }}
                          >
                            <input
                              type="checkbox"
                              checked={cat}
                              onChange={handleChangeCat}
                            />
                            <span className="sliderDemo"></span>
                          </label>{" "}
                          <div style={{ textAlign: "center" }}>
                            <label style={{ marginTop: "0.5rem" }}>
                              Category
                            </label>
                          </div>
                        </div>
                        <div className="flex mt-6 justify-content-start align-items-center">
                          <label
                            className="switch "
                            style={{ display: "block", margin: "0 1vw" }}
                          >
                            <input
                              type="checkbox"
                              checked={logo}
                              onChange={handleChangeLogo}
                            />
                            <span className="sliderDemo"></span>
                          </label>{" "}
                          <div style={{ textAlign: "center" }}>
                            <label style={{ marginTop: "0.5rem" }}>
                              Merchant Logo
                            </label>
                          </div>
                        </div>
                        <div className="flex mt-6 justify-content-start align-items-center">
                          <label
                            className="switch "
                            style={{ display: "block", margin: "0 1vw" }}
                          >
                            <input
                              type="checkbox"
                              onChange={handleChangeCleanDescription}
                            />
                            <span class="sliderDemo"></span>
                          </label>{" "}
                          <div style={{ textAlign: "center" }}>
                            <label style={{ marginTop: "0.5rem" }}>
                              Clean Description
                            </label>
                          </div>
                        </div>

                        <div className="flex mt-6 justify-content-start align-items-center">
                          <label
                            className="switch "
                            style={{ display: "block", margin: "0 1vw" }}
                          >
                            <input
                              type="checkbox"
                              checked={merchantWebsite}
                              onChange={handleChangeWebsite}
                            />
                            <span className="sliderDemo"></span>
                          </label>{" "}
                          <div style={{ textAlign: "center" }}>
                            <label style={{ marginTop: "0.5rem" }}>
                              Merchant Website
                            </label>
                          </div>
                        </div>
                      </Row>
                      <Row className="gap-4 mt-6">
                        <div className="flex  justify-content-start align-items-center">
                          <label
                            className="switch "
                            style={{ display: "block", margin: "0 1vw" }}
                          >
                            <input
                              type="checkbox"
                              onChange={handleChangeFootprint}
                            />
                            <span className="sliderDemo green"></span>
                          </label>{" "}
                          <div style={{ textAlign: "center" }}>
                            <label style={{ marginTop: "0.5rem" }}>
                              {"CO"}
                              <sub>2</sub>
                              {" Footprint"}
                            </label>
                          </div>
                        </div>
                      </Row>
                      <ReCAPTCHA
                        className="rc-anchor-dark mt-6 flex justify-content-end"
                        theme="dark"
                        size="normal"
                        onExpired={() => {
                          setButtonState("disabled");
                        }}
                        onChange={onVerification}
                        sitekey="6LeUNCYaAAAAAJ_w6RFTL9rHmMAUTijZ16IVPwJC"
                      />
                    </Col>
                    <ProdApiDemo
                      catValue={catValue}
                      logoValue={logoValue}
                      footprintValue={footprintValue}
                      cleanDescriptionValue={cleanDescriptionValue}
                      query={query}
                      loading={loading}
                      message={responseMessage}
                      buttonState={buttonState}
                      merchantWebsiteValue={merchantWebsiteValue}
                    />
                    {/* </div> */}
                  </Row>
                </Form>
                <p className="mt-12 text-sm text-gray-500">
                  Freely test Genify's flagship product, Transaction
                  Categorization API. It overcomes limitations of other
                  enrichment services that accept data from only few countries.
                  Genify's enrichment service is country-agnostic. Prospective
                  users, including banks and fintech companies, can test
                  Genify's Transaction Categorization API above and experience
                  its power first hand. You can input a banking transaction’s
                  description and amount, then choose the enrichment fields you
                  want returned, such as the category or logo. Additionally, you
                  can select the country where the transaction was made and the
                  currency corresponding to the input amount.
                </p>
              </Col>
            </Container>
          </Row>
        </div>
      </PageWrapper>
    </>
  );
};

export default ProdDemo;
