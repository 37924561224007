import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import "react-css-dropdown/dist/index.css";
import { PropertyMixer } from "three";
import ReCAPTCHA from "react-google-recaptcha";

const ProdApiDemo = (props) => {
  // const [buttonState, setButtonState] = useState("disabled");

  // let onVerification = () => setButtonState("enabled");

  const [isImageBroken, setIsImageBroken] = useState(true);

  function checkImage(url) {
    return fetch(url, { cache: "no-store" })
      .then((response) => {
        console.log(response.body);

        return response.json();
      })
      .then((body) => {
        // if body has Status property, it is not an image
        // if (body.Status) {
        console.log("The URL does not return an image", url, body);
        return false;
        // } else {
        //   console.log("The URL returns an image");
        //   return true;
        // }
      })
      .catch((error) => {
        console.log("image ok");

        console.error(error);
        return true;
      });
  }

  useEffect(() => {
    if (!props.logoValue) return;
    setIsImageBroken(true);

    const intervalId = setInterval(() => {
      checkImage(props.logoValue)
        .then((isImage) => {
          if (isImage) {
            setIsImageBroken(false);
            clearInterval(intervalId);
          } else {
            // console.log("The URL does not return an image");
            // console.log(props.logoValue);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [props.logoValue]);

  return (
    <Row
      className="mt-10 flex-1"
      style={{ justifyContent: "center", minWidth: 400, marginInline: 5 }}
    >
      <Col>
        <div className="fakeMenu">
          <div className="fakeButtons fakeClose"></div>
          <div className="fakeButtons fakeMinimize"></div>
          <div className="fakeButtons fakeZoom"></div>
        </div>

        <div className="fakeScreenDemo">
          <div className="terminal-input-request">
            <code>
              <div className="fragment">
                <span style={{ color: "#79e7f4" }}>{"{"}</span>
                <br />
              </div>
              {"       "}
              <div className="container-tab">
                <div className="fragment">
                  <span style={{ color: "#79e7f4" }}>&quot;Status&quot;:</span>{" "}
                  <span style={{ color: "#b0ec6d" }}>
                    &quot;{props.message}&quot;,
                  </span>
                  <br />
                </div>{" "}
                <div className="fragment">
                  <span style={{ color: "#79e7f4" }}>
                    &quot;Category&quot;:
                  </span>{" "}
                  <span style={{ color: "#b0ec6d" }}>
                    &quot;{props.catValue}&quot;,
                  </span>
                  <br />
                </div>{" "}
                <div className="fragment">
                  <span style={{ color: "#79e7f4" }}>
                    &quot;Carbon Footprint&quot;:
                  </span>{" "}
                  <span style={{ color: "#b0ec6d" }}>
                    {/* &quot;{props.footprintValue}&quot;, */}
                    &quot;
                    {props.footprintValue
                      ? "CO2 footprint not available in playground"
                      : ""}
                    &quot;,
                  </span>
                  <br />
                </div>{" "}
                <div className="fragment">
                  <span style={{ color: "#79e7f4" }}>&quot;Logo&quot;:</span>{" "}
                  <span style={{ color: "#b0ec6d" }}>
                    {props.logoValue && (
                      <>
                        {isImageBroken ? (
                          <div
                            className="spinner-border"
                            role="status"
                            style={{ width: "1rem", height: "1rem" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <img
                            src={props.logoValue}
                            className="logo"
                            alt="sample logo"
                            style={{ width: "2rem", height: "2rem" }}
                          />
                        )}

                        {/* <img
                          className="logo"
                          alt="sample logo"
                          src={logoReturned()}
                        /> */}
                      </>
                    )}
                    ,
                  </span>
                  <br />
                </div>{" "}
                <div className="fragment">
                  <span style={{ color: "#79e7f4" }}>
                    &quot;Clean Description&quot;:
                  </span>{" "}
                  <span style={{ color: "#b0ec6d" }}>
                    &quot;
                    {props.cleanDescriptionValue}
                    &quot;,
                  </span>
                  <br />
                </div>{" "}
                <div className="fragment">
                  <span style={{ color: "#79e7f4" }}>
                    &quot;Merchant Website&quot;:
                  </span>{" "}
                  <span style={{ color: "#b0ec6d" }}>
                    &quot;
                    {props.merchantWebsiteValue}
                    &quot;,
                  </span>
                  <br />
                </div>{" "}
              </div>{" "}
              <div className="fragment">
                <span
                  style={{ color: "#79e7f4" }}
                  data-v-5d621296
                  data-v-0bd6bc83
                >
                  {"}"}
                </span>
              </div>
            </code>
          </div>
        </div>
        <div className="fakeScreenFooter">
          <div className="screenLanguage">json</div>
          {/* <ReCAPTCHA
              className="rc-anchor-dark"
              theme="dark"
              size="normal"
              onExpired={() => {
                setButtonState("disabled");
              }}
              onChange={onVerification}
              sitekey="6LeUNCYaAAAAAJ_w6RFTL9rHmMAUTijZ16IVPwJC"
            /> */}
          <div className="twoToneCenter ">
            <button
              type="submit"
              disabled={props.buttonState === "disabled"}
              className={`twoToneButton gr-hover-y ${props.buttonState}`}
            >
              {props.loading ? (
                <>
                  Running{" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                </>
              ) : (
                "Run"
              )}
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ProdApiDemo;
